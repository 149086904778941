<template>
  <Card
    :class="$style.dialog"
    :has-body-padding="!inMapCard"
    :has-body-padding-small="inMapCard"
    :has-body-shadow="hasBodyShadow"
    :show-close-button="true"
    @close-button-click="handleClose"
  >
    <template #body>
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
      >
        <div
          v-if="showLoading"
          :class="loadingClass"
        >
          <LoadingIndicator />
        </div>
      </transition>
      <h2 class="tw-mb-2 tw-text-3xl tw-font-medium tw-text-center">
        <span>Select List</span>
      </h2>
      <p class="tw-mb-2">
        {{ message }}
        <a
          class="tw-underline link-color-brand"
          href="https://support.opensnow.com/feature-guides/favorite-lists"
          rel="noopener noreferrer"
          target="_blank"
        >Learn More</a> about Favorite lists.
      </p>
      <ul class="tw-mb-4">
        <li
          v-for="list in lists"
          :key="list.id"
          class="tw-mb-1"
        >
          <label>
            <Checkbox
              class="tw-mr-2 tw-align-middle"
              v-bind="getInputProps(list)"
              @update:model-value="(checked) => updateSelectedLists(checked, list)"
            />
            {{ list.name }}
          </label>
        </li>
      </ul>
      <Button
        class="tw-w-full"
        :disabled="isButtonDisabled"
        :display-block="true"
        type="primary"
        @click="handleContinue"
      >
        {{ buttonText }}
      </Button>
      <AddRelatedFavoritesDisclaimer
        v-if="isAddView && canShowAddRelatedFavoritesDisclaimer"
        additional-class-names="tw-pt-4"
      />
    </template>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { areArraysDifferent } from '@@/utils/CommonUtils';
import { mapCardTypes, useMapStore } from '@@/stores/Map';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import FavoritesStorageMixin from '@@/components/Favorites/FavoritesStorageMixin';

export default {
  name: 'SelectFavoriteListCard',

  mixins: [FavoritesStorageMixin],

  props: {
    action: {
      type: String,
      default: 'add',
      validator(value) {
        return ['add', 'update'].includes(value);
      },
    },
    cam: {
      type: Object,
      default: null,
    },
    dailyRead: {
      type: Object,
      default: null,
    },
    defaultSelectedLists: {
      type: Array,
      default: null,
    },
    hasBodyShadow: {
      type: Boolean,
      default: false,
    },
    inMapCard: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: null,
    },
    seasonPass: {
      type: Object,
      default: null,
    },
    trailMap: {
      type: Object,
      default: null,
    },
  },

  emits: ['close', 'continue'],

  data() {
    return {
      initialSelectedLists: [],
      selectedLists: [],
      showLoading: false,
    };
  },

  computed: {
    ...mapState(useUserFavoritesStore, {
      activeList: 'getActiveList',
      lists: 'getLists',
    }),

    buttonText() {
      if (this.isUpdateView) {
        return 'Update';
      }

      return 'Continue';
    },

    canShowAddRelatedFavoritesDisclaimer() {
      return this.hasLocation && !this.hasCam && !this.hasTrailMap;
    },

    hasCam() {
      return !!this.cam;
    },

    hasDailyRead() {
      return !!this.dailyRead;
    },

    hasLocation() {
      return !!this.location;
    },

    hasSeasonPass() {
      return !!this.seasonPass;
    },

    hasTrailMap() {
      return !!this.trailMap;
    },

    isAddView() {
      return this.action === 'add';
    },

    isButtonDisabled() {
      if (this.isAddView) {
        return this.selectedLists.length === 0;
      }

      return !areArraysDifferent(this.selectedLists, this.initialSelectedLists);
    },

    isUpdateView() {
      return this.action === 'update';
    },

    loadingClass() {
      return [
        'tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-20',
        this.$style.loading,
      ];
    },

    message() {
      let resource = 'location';

      if (this.hasCam) {
        resource = 'webcam';
      }
      else if (this.hasDailyRead) {
        resource = 'daily snow';
      }
      else if (this.hasSeasonPass) {
        resource = 'season pass';
      }
      else if (this.hasTrailMap) {
        resource = 'trail map';
      }

      if (this.isAddView) {
        return `Add this ${resource} to one or more of your Favorite lists.`;
      }

      return `Add or remove this ${resource} from your Favorite lists.`;
    },
  },

  mounted() {
    // Make a deep copy of the  default selected lists because it is passed from the store and
    // later changes to the selected lists would result in a run time error caused by modifying the
    // store outside a mutation.
    this.selectedLists = this.defaultSelectedLists?.length
      ? [...this.defaultSelectedLists]
      : [this.activeList.id];

    // Keep track of the initial selected lists so that the Action Button can remain disabled when
    // updating the favorite lists until the selection has changed.
    this.initialSelectedLists = [...this.selectedLists];
  },

  methods: {
    ...mapActions(useMapStore, ['setMapCard']),
    ...mapActions(useUserFavoritesStore, ['showSelectFavoriteListDialog']),

    getInputProps(list) {
      const checked = this.selectedLists.includes(list.id);

      if (this.isAddView || this.isUpdateView) {
        return {
          modelValue: checked,
        };
      }

      return {
        checked,
        name: 'selectedList',
        modelValue: String(list.id),
      };
    },

    handleClose() {
      this.$emit('close');
    },

    async handleContinue() {
      if (this.inMapCard) {
        if (this.isAddView && this.selectedLists.length === 0) {
          return;
        }

        if (this.isAddView && this.location.is_custom) {
          this.showSaveCustomLocationCard();
          return;
        }

        if (this.isUpdateView && this.location.is_custom && this.selectedLists.length === 0) {
          this.showRemoveCustomLocationDialog();
          return;
        }

        this.showLoading = true;

        const { location } = this;

        if (this.isAddView) {
          await this.addFavorite({ location }, this.selectedLists);
        }
        else {
          await this.updateFavorite({ location }, this.selectedLists);
        }

        this.showLoading = false;
        this.$emit('close');

        return;
      }

      if (this.hasCam || this.hasDailyRead || this.hasSeasonPass || this.hasTrailMap) {
        const {
          cam,
          dailyRead,
          location,
          seasonPass,
          trailMap,
        } = this;

        let resource;

        if (this.hasCam) {
          resource = { cam, location };
        }
        else if (this.hasDailyRead) {
          resource = { dailyRead };
        }
        else if (this.hasSeasonPass) {
          resource = { seasonPass };
        }
        else {
          resource = { trailMap, location };
        }

        this.showLoading = true;

        if (this.isAddView) {
          await this.addFavorite(resource, this.selectedLists);
        }
        else {
          await this.updateFavorite(resource, this.selectedLists);
        }

        this.showLoading = false;
        this.showSelectFavoriteListDialog(null);

        return;
      }

      const { selectedLists } = this;
      this.$emit('continue', selectedLists);
    },

    showRemoveCustomLocationDialog() {
      this.setMapCard({
        data: {
          location: this.location,
          selectedLists: this.selectedLists,
        },
        type: mapCardTypes.removeCustomLocationDialog,
      });
    },

    showSaveCustomLocationCard() {
      this.setMapCard({
        data: {
          location: this.location,
          selectedLists: this.selectedLists,
        },
        type: mapCardTypes.saveCustomLocation,
      });
    },

    updateSelectedLists(checked, list) {
      if (checked) {
        if (this.isAddView || this.isUpdateView) {
          if (this.selectedLists.includes(list.id) === false) {
            this.selectedLists.push(list.id);
          }
        }
        else {
          this.selectedLists = [list.id];
        }
      }
      else if (!checked) {
        const index = this.selectedLists.findIndex((id) => id === list.id);

        if (index >= 0) {
          this.selectedLists.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style module>
.dialog {
  width: 100%;
}

@media (min-width: 992px) {
  .dialog {
    width: 30rem;
  }
}

.loading {
  background-color: rgb(var(--card-background-rgb) / 50%);
}
</style>
