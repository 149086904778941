<script>
import { useInputStripeColor } from '@@/composables/useInputStripeColor';

const { inputProps, getInputClassName } = useInputStripeColor();
</script>

<script setup>
/**
 * Custom styled checkbox input with v-model interface.
 * @see https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  ...inputProps,
});

defineEmits(['update:modelValue']);

const inputClass = computed(() => {
  return [
    'tw-appearance-none',
    'tw-relative',
    'tw-inline-block',
    'tw-h-5',
    'tw-w-5',
    'tw-border-2',
    'tw-rounded-md',
    'card-background-color',
    'border-color',
    'tw-transition-all',
    'input-checkable',
    getInputClassName(props),
  ];
});
</script>

<template>
  <input
    :checked="modelValue"
    :class="inputClass"
    type="checkbox"
    @input="(e) => $emit('update:modelValue', e.target.checked)"
  >
</template>
