export const useInputStripeColor = () => {
  const inputProps = {
    useStripeColor: {
      type: Boolean,
      default: false,
    },
  };

  const getInputClassName = (props) => props.useStripeColor ? 'input-checkable-stripe-color' : '';

  return {
    getInputClassName,
    inputProps,
  };
};
